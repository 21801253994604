* {
  font-family: "Lato", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.raffleBox:hover {
  cursor: pointer;
}

.popup {
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: white;
}

.back:hover {
  color: #42bb39;
}

.logoDiv {
  cursor: pointer;
}


.loginDiv {
  display: flex;
}


.button-7:hover {
  animation: shake 2s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);

  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}


.light {
  min-height: 100vh;
  height: max-content;
}

::-webkit-scrollbar {
  width: 15px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #96df48;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(228, 171, 171);
}

@media screen and (max-width: 499px) {
  .mainR2 {
    margin-top: 40px;
  }

  .endDate {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 5px;
  }

  .endDate img {
    width: 25px;
    height: 25px;
    margin-top: -3px;
    margin-left: 10px;
  }

  .location {
    margin-top: 20px;
  }

  .popup-content2 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.062);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .picAndDetails {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .detailslPic {
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .s1H {
    font-size: 22px;
    color: black;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .s1Sub {
    font-size: 14px;
  }

  .mainR {
    width: 100%;
    margin-left: 5%;
  }

  .amountR {
    font-weight: bolder;
    font-size: 17px;
  }

  .description {
    font-size: 18px;
    margin-top: 20px;
  }

  .lineDash {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.192);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .s2H {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .s2H img {
    width: 20px;
    height: 20px;
    margin-top: -4px;
    margin-right: 5px;
  }

  .flexDetails {
    display: flex;
    justify-content: space-between;
  }

  .leftS {
    font-size: 16px;
    font-weight: bolder;
  }

  .rightS {
    font-size: 16px;
  }

  .section2 {
    margin-bottom: 20px;
  }

  .bar {
    height: 12px;
    width: 100%;
    background-color: #01840c2d;
    border-radius: 100px;
  }

  .note {
    font-size: 14px;
    color: #00000077;
  }

  .user img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .user {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    z-index: 10;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    padding: 5px;
    padding-bottom: 10px;
    width: 100%;
  }

  .timer div {
    text-align: center;
    width: 50px;
  }

  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  }

  .userMain {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    flex-flow: column nowrap;
  }

  .ticketDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .ticketDetails img {
    width: 70px;
    height: 70px;
  }

  .ticketDetails div {
    font-size: 14px;
    color: #808080;
    text-align: center;
  }

  .userTicket {
    background-color: #43913d33;
    width: 100%;
    padding: 20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .userDetails {
    width: 100%;
    padding: 20px;
    background-color: #413f3f13;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

  }

  .note2Main {
    margin-top: 20px;

  }

  .note2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .noteC {
    width: 100%;
  }

  .report {
    margin: auto;
  }

  .report button {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 81, 81, 0);
    border: 1px solid rgb(248, 101, 101);
    border-radius: 100px;
    font-size: 18px;
    color: rgb(248, 101, 101);
    padding: 10px;
    width: 250px;
    transition: 0.3s ease-in-out;
    margin-top: 20px;
  }

  .report button:hover {
    background-color: rgb(248, 101, 101);
    color: white;
  }

  .back {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px;
    color: #4ba345;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .back img {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-top: -5px;
    transition: 0.3s ease-in-out;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #14552252;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .navbar {
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1d1d1d;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #4ba345 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
    padding-top: 10px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .login {
    font-size: 15px;
    border-radius: 100px;
    background-color: #4ba345;
    color: white;
    border: none;
    width: 100px;
    padding: 10px;
    transition: 0.5s ease-in-out;
    margin-right: 20px;

  }

  .login:hover {
    background-color: #5cc954;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
 
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 3px;
    transition: transform .6s;
    background-color: white;
    border-radius: 100%;
    border: 1px solid white;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .allWrap {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0.116);

  }

  .cont {
    background: url('./assets/bg.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    margin-top: 50px;
    width: 90%;
    text-align: center;
  }

  .introduction {
    margin: auto;
    text-align: center;
  }

  .banner {
    width: 95%;
    height: 95%;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -50px;
  }

  #centerF {
    text-align: center;
  }

  .line {
    width: 100%;
  }

  .logoPic {
    width: 100px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .title {
    z-index: 1;
    font-size: 30px;
    font-weight: bold;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    background-color: #ffb84d;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    padding: 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 100px;

  }

  .subTitle {
    font-size: 18px;
    color: #413f3f;
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .button-7 {
    margin-top: 20px;
    border: 1px solid orange;
    border-radius: 50px;
    font-size: 17px;
    width: 150px;
    padding: 10px;
    background-color: white;
  }

  .bold {
    font-weight: bold;
  }

  .about {
    background-color: #ffffff;
    padding-bottom: 30px;
    padding-top: 60px;
  }

  .steps {
    background-color: #e5852e;
    padding-bottom: 60px;
    padding-top: 60px;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .introC {
    background-color: #01840d;
    padding-bottom: 60px;
    background: url('./assets/6230704.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .line2 {
    width: 100%;
  }

  .titleA {
    font-size: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    background-color: #ffb84d;
    padding: 10px;
    margin-bottom: 2%;
  }

  .titleS {
    font-size: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    margin-bottom: 2%;
    text-align: center;
  }

  .img1B {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .aboutSection1 div {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    width: 80%;
    font-size: 14px;

  }

  .aboutSection2 {
    display: flex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: none;
  }

  .aboutSection2Mobile {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .aboutSection2Mobile-sub {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
  }


  .aboutSection2Sub {
    width: 500px;
  }

  .aboutSection2Sub div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 14px;
  }

  .aboutSection2Sub-2 img {
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub img {
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub-2 {
    width: 500px;
  }

  .aboutSection2Sub-2 div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 14px;
  }

  .titleS2 {
    font-size: 18px;
    color: white;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
  }

  .contents {
    display: flex;
    color: white;
    font-size: 14px;
  }

  .stepsBtn {
    margin-top: 20px;
    padding: 12px;
    border-radius: 100px;
    border: none;
    width: 170px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    transition: transform .6s;

  }

  .stepsBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contents1 {
    margin-right: 30px;
  }

  .steps img {
    width: 65%;
    height: 65%;
    margin-left: auto;
    margin-right: auto;
  }

  .contentsMain {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .mobileSection {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    padding-top: 30px;
    margin-bottom: 20px;
  }

  .mobCon1 {
    font-size: 17px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 100%;
    text-align: center;
  }

  .mobCon2 {
    font-size: 17px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .mobileSection img {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .imgAndConMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .imgAndCon img {
    width: 60%;
    justify-content: center;
    margin: auto;
    display: flex;
  }

  .imgAndCon div {
    font-size: 18px;
    color: #f0d975;
    font-weight: bolder;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .imgAndCon p {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 100%;
    margin: auto;
    font-size: 14px;
  }

  /* General Footer Styles */
  .simple-footer {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    font-family: Arial, sans-serif;

    padding-bottom: 50px;
  }

  .simple-footer .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .simple-footer .footer-content {
    display: flex;
    justify-content: center;
    width: fit-content;
    flex-wrap: wrap;
  }

  .simple-footer .footer-section {
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
  }

  .simple-footer .footer-section h2 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .simple-footer .footer-section p,
  .simple-footer .footer-section ul,
  .simple-footer .footer-section a {
    font-size: 12px;
    color: #ccc;
    text-decoration: none;
    margin: 5px 0;
  }

  .simple-footer .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .simple-footer .footer-section ul li a:hover {
    color: #fff;
  }

  /* Footer Bottom */
  .simple-footer .footer-bottom {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #444;
    margin-top: 20px;
    width: 100%;
  }

  .simple-footer .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }

  .raffleSection {
    margin-top: 50px;
    margin-bottom: 80px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .raffleBox {
    background-color: rgb(228, 226, 226);
    border-radius: 20px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .mainRI {
    width: 80%;
    margin: auto;
    justify-content: center;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
  }

  .raffleCon {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .amount {
    font-size: 15px;
    font-weight: bolder;
  }

  .amountCon {
    font-size: 12px;
  }

  .tickets {
    display: flex;
    justify-content: center;
  }

  .tickets img {
    width: 18px;
    height: 18px;
    margin-top: 1.1px;
    margin-right: 10px;
  }

  .tickets div {
    color: #ff3700;
    font-weight: bold;
    font-size: 14px;
  }
}


@media screen and (min-width: 500px) and (max-width: 767px) {
  .mainR2 {
    margin-top: 40px;
  }
  .login {
    font-size: 15px;
    border-radius: 100px;
    background-color: #4ba345;
    color: white;
    border: none;
    width: 100px;
    padding: 10px;
    transition: 0.5s ease-in-out;
    margin-right: 20px;
  }

  .login:hover {
    background-color: #5cc954;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .endDate {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 5px;
  }

  .endDate img {
    width: 25px;
    height: 25px;
    margin-top: -3px;
    margin-left: 10px;
  }

  .location {
    margin-top: 20px;
  }

  .popup-content2 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.062);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .picAndDetails {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .detailslPic {
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .s1H {
    font-size: 22px;
    color: black;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .s1Sub {
    font-size: 14px;
  }

  .mainR {
    width: 100%;
    margin-left: 5%;
  }

  .amountR {
    font-weight: bolder;
    font-size: 17px;
  }

  .description {
    font-size: 18px;
    margin-top: 20px;
  }

  .lineDash {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.192);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .s2H {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .s2H img {
    width: 20px;
    height: 20px;
    margin-top: -4px;
    margin-right: 5px;
  }

  .flexDetails {
    display: flex;
    justify-content: space-between;
  }

  .leftS {
    font-size: 16px;
    font-weight: bolder;
  }

  .rightS {
    font-size: 16px;
  }

  .section2 {
    margin-bottom: 20px;
  }

  .bar {
    height: 12px;
    width: 100%;
    background-color: #01840c2d;
    border-radius: 100px;
  }

  .note {
    font-size: 14px;
    color: #00000077;
  }

  .user img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .user {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    z-index: 10;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    padding: 5px;
    padding-bottom: 10px;
    width: 100%;
  }

  .timer div {
    text-align: center;
    width: 73px;
  }

  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  }

  .userMain {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    flex-flow: column nowrap;
  }

  .ticketDetailsMain {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    width: 100%;
  }

  .ticketDetails img {
    width: 100px;
    height: 100px;
  }

  .ticketDetails div {
    font-size: 14px;
    color: #808080;
    text-align: center;
  }

  .userTicket {
    background-color: #43913d33;
    width: 100%;
    padding: 20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .userDetails {
    width: 100%;
    padding: 20px;
    background-color: #413f3f13;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

  }

  .note2Main {
    margin-top: 20px;

  }

  .note2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .noteC {
    width: 100%;
  }

  .report {
    margin: auto;
  }

  .report button {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 81, 81, 0);
    border: 1px solid rgb(248, 101, 101);
    border-radius: 100px;
    font-size: 18px;
    color: rgb(248, 101, 101);
    padding: 10px;
    width: 250px;
    transition: 0.3s ease-in-out;
    margin-top: 20px;
  }

  .report button:hover {
    background-color: rgb(248, 101, 101);
    color: white;
  }

  .back {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px;
    color: #4ba345;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .back img {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-top: -5px;
    transition: 0.3s ease-in-out;
  }


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #14552252;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .navbar {
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1d1d1d;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #4ba345 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
    padding-top: 10px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 3px;
    transition: transform .6s;
    background-color: white;
    border-radius: 100%;
    border: 1px solid white;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .allWrap {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0.116);
  }

  .cont {
    background: url('./assets/bg.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    margin-top: 50px;
    width: 90%;
    text-align: center;
  }

  .introduction {
    margin: auto;
    text-align: center;
  }

  .banner {
    width: 95%;
    height: 95%;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -50px;
  }

  #centerF {
    text-align: center;
  }

  .line {
    width: 100%;
  }

  .logoPic {
    width: 100px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .title {
    z-index: 1;
    font-size: 38px;
    font-weight: bold;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    background-color: #ffb84d;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    padding: 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 100px;
  }

  .subTitle {
    font-size: 18px;
    color: #413f3f;
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .button-7 {
    margin-top: 20px;
    border: 1px solid orange;
    border-radius: 50px;
    font-size: 17px;
    width: 150px;
    padding: 10px;
    background-color: white;
  }

  .bold {
    font-weight: bold;
  }

  .about {
    background-color: #ffffff;
    padding-bottom: 30px;
    padding-top: 60px;
  }

  .steps {
    background-color: #e5852e;
    padding-bottom: 60px;
    padding-top: 60px;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .introC {
    background-color: #01840d;
    padding-bottom: 60px;
    background: url('./assets/6230704.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .line2 {
    width: 100%;
  }

  .titleA {
    font-size: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    background-color: #ffb84d;
    padding: 10px;
    margin-bottom: 2%;
  }

  .titleS {
    font-size: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    margin-bottom: 2%;
    text-align: center;
  }

  .img1B {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .aboutSection1 div {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    width: 50%;
    font-size: 14px;

  }

  .aboutSection2 {
    display: flex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: none;
  }

  .aboutSection2Mobile {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .aboutSection2Mobile-sub {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
  }


  .aboutSection2Sub {
    width: 500px;
  }

  .aboutSection2Sub div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 14px;
  }

  .aboutSection2Sub-2 img {
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub img {
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub-2 {
    width: 500px;
  }

  .aboutSection2Sub-2 div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 14px;
  }

  .titleS2 {
    font-size: 18px;
    color: white;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
  }

  .contents {
    display: flex;
    color: white;
    font-size: 14px;
  }

  .stepsBtn {
    margin-top: 20px;
    padding: 12px;
    border-radius: 100px;
    border: none;
    width: 170px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    transition: transform .6s;

  }

  .stepsBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contents1 {
    margin-right: 30px;
  }

  .steps img {
    width: 50%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .contentsMain {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .mobileSection {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    padding-top: 30px;
    margin-bottom: 20px;
  }

  .mobCon1 {
    font-size: 17px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 100%;
    text-align: center;
  }

  .mobCon2 {
    font-size: 17px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .mobileSection img {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .imgAndConMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .imgAndCon img {
    width: 60%;
    justify-content: center;
    margin: auto;
    display: flex;
  }

  .imgAndCon div {
    font-size: 18px;
    color: #f0d975;
    font-weight: bolder;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .imgAndCon p {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 100%;
    margin: auto;
    font-size: 14px;
  }

  /* General Footer Styles */
  .simple-footer {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    font-family: Arial, sans-serif;

    padding-bottom: 50px;
  }

  .simple-footer .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .simple-footer .footer-content {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    flex-wrap: wrap;

  }

  .simple-footer .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 150px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
  }

  .simple-footer .footer-section h2 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .simple-footer .footer-section p,
  .simple-footer .footer-section ul,
  .simple-footer .footer-section a {
    font-size: 12px;
    color: #ccc;
    text-decoration: none;
    margin: 5px 0;
  }

  .simple-footer .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .simple-footer .footer-section ul li a:hover {
    color: #fff;
  }

  /* Footer Bottom */
  .simple-footer .footer-bottom {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #444;
    margin-top: 20px;
    width: 100%;
  }

  .simple-footer .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }

  .raffleSection {
    margin-top: 50px;
    margin-bottom: 80px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .raffleBox {
    background-color: rgb(228, 226, 226);
    border-radius: 20px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .mainRI {
    width: 80%;
    margin: auto;
    justify-content: center;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
  }

  .raffleCon {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .amount {
    font-size: 15px;
    font-weight: bolder;
  }

  .amountCon {
    font-size: 12px;
  }

  .tickets {
    display: flex;
    justify-content: center;
  }

  .tickets img {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    margin-right: 10px;
  }

  .tickets div {
    color: #ff3700;
    font-weight: bold;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mainR2 {
    margin-top: 40px;
  }

  .endDate {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 5px;
  }

  .endDate img {
    width: 25px;
    height: 25px;
    margin-top: -3px;
    margin-left: 10px;
  }

  .location {
    margin-top: 20px;
  }

  .popup-content2 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.062);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .picAndDetails {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .detailslPic {
    width: 65%;
    height: 65%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .s1H {
    font-size: 22px;
    color: black;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .s1Sub {
    font-size: 14px;
  }

  .mainR {
    width: 100%;
    margin-left: 5%;
  }

  .amountR {
    font-weight: bolder;
    font-size: 17px;
  }

  .description {
    font-size: 18px;
    margin-top: 20px;
  }

  .lineDash {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.192);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .s2H {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .s2H img {
    width: 20px;
    height: 20px;
    margin-top: -4px;
    margin-right: 5px;
  }

  .flexDetails {
    display: flex;
    justify-content: space-between;
  }

  .leftS {
    font-size: 16px;
    font-weight: bolder;
  }

  .rightS {
    font-size: 16px;
  }

  .section2 {
    margin-bottom: 20px;
  }

  .bar {
    height: 12px;
    width: 100%;
    background-color: #01840c2d;
    border-radius: 100px;
  }

  .note {
    font-size: 14px;
    color: #00000077;
  }

  .user img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .user {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    z-index: 10;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    padding: 5px;
    padding-bottom: 10px;
    width: 80%;
  }

  .timer div {
    text-align: center;
    width: 73px;
  }

  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  }

  .userMain {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    flex-flow: column nowrap;
  }

  .ticketDetailsMain {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    width: 100%;
  }

  .ticketDetails img {
    width: 100px;
    height: 100px;
  }

  .ticketDetails div {
    font-size: 14px;
    color: #808080;
    text-align: center;
  }

  .userTicket {
    background-color: #43913d33;
    width: 100%;
    padding: 20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .userDetails {
    width: 100%;
    padding: 20px;
    background-color: #413f3f13;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

  }

  .note2Main {
    margin-top: 3%;

  }

  .note2 {
    display: flex;
    justify-content: space-between;
  }

  .noteC {
    width: 80%;
  }

  .report {
    margin: auto;
  }

  .report button {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 81, 81, 0);
    border: 1px solid rgb(248, 101, 101);
    border-radius: 100px;
    font-size: 18px;
    color: rgb(248, 101, 101);
    padding: 10px;
    width: 250px;
    transition: 0.3s ease-in-out;
  }

  .report button:hover {
    background-color: rgb(248, 101, 101);
    color: white;
  }

  .back {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px;
    color: #4ba345;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .back img {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-top: -5px;
    transition: 0.3s ease-in-out;
  }


  .aboutSection2Mobile {
    display: none;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #14552252;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .navbar {
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1d1d1d;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #4ba345 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
    padding-top: 10px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .login {
    font-size: 15px;
    border-radius: 100px;
    background-color: #4ba345;
    color: white;
    border: none;
    width: 100px;
    padding: 10px;
    transition: 0.5s ease-in-out;
    margin-right: 20px;
  }

  .login:hover {
    background-color: #5cc954;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 3px;
    transition: transform .6s;
    background-color: white;
    border-radius: 100%;
    border: 1px solid white;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .allWrap {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0.116);
  }

  .cont {
    background: url('./assets/bg.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    margin-top: 50px;
    width: 90%;
    text-align: center;
  }

  .introduction {
    margin: auto;
    text-align: center;
  }

  .banner {
    width: 75%;
    height: 75%;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -50px;
  }

  #centerF {
    text-align: center;
  }

  .line {
    width: 100%;
  }

  .logoPic {
    width: 100px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .title {
    z-index: 1;
    font-size: 38px;
    font-weight: bold;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    background-color: #ffb84d;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    padding: 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 100px;

  }

  .subTitle {
    font-size: 18px;
    color: #413f3f;
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .button-7 {
    margin-top: 20px;
    border: 1px solid orange;
    border-radius: 50px;
    font-size: 17px;
    width: 150px;
    padding: 10px;
    background-color: white;
  }

  .bold {
    font-weight: bold;
  }

  .about {
    background-color: #ffffff;
    padding-bottom: 30px;
    padding-top: 60px;
  }

  .steps {
    background-color: #e5852e;
    padding-bottom: 60px;
    padding-top: 60px;
    display: flex;
    justify-content: space-evenly;
  }

  .introC {
    background-color: #01840d;
    padding-bottom: 60px;
    background: url('./assets/6230704.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .line2 {
    width: 100%;
  }

  .titleA {
    font-size: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    background-color: #ffb84d;
    padding: 10px;
    margin-bottom: 2%;
  }

  .titleS {
    font-size: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    margin-bottom: 2%;
  }

  .img1B {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .aboutSection1 div {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    width: 50%;
    font-size: 14px;

  }

  .aboutSection2 {
    display: flex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }


  .aboutSection2Sub {
    width: 500px;
  }

  .aboutSection2Sub div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 14px;
  }

  .aboutSection2Sub-2 img {
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub img {
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub-2 {
    width: 500px;
    margin-top: 70px;
  }

  .aboutSection2Sub-2 div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 14px;
  }

  .titleS2 {
    font-size: 18px;
    color: white;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .contents {
    display: flex;
    color: white;
    font-size: 14px;

  }

  .stepsBtn {
    margin-top: 20px;
    padding: 12px;
    border-radius: 100px;
    border: none;
    width: 170px;
    font-weight: bold;
    transition: transform .6s;

  }

  .stepsBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contents1 {
    margin-right: 30px;
  }

  .steps img {
    width: 40%;
    height: 40%;
  }

  .contentsMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobileSection {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .mobCon1 {
    font-size: 20px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 35%;
  }

  .mobCon2 {
    font-size: 20px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    text-align: right;
    width: 35%;
  }

  .mobileSection img {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .imgAndConMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .imgAndCon img {
    width: 60%;
    justify-content: center;
    margin: auto;
    display: flex;
  }

  .imgAndCon div {
    font-size: 22px;
    color: #f0d975;
    font-weight: bolder;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .imgAndCon p {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 90%;
    margin: auto;
  }

  /* General Footer Styles */
  .simple-footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    font-family: Arial, sans-serif;

    padding-bottom: 50px;
  }

  .simple-footer .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .simple-footer .footer-content {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    flex-wrap: wrap;

  }

  .simple-footer .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .simple-footer .footer-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .simple-footer .footer-section p,
  .simple-footer .footer-section ul,
  .simple-footer .footer-section a {
    font-size: 14px;
    color: #ccc;
    text-decoration: none;
    margin: 5px 0;
  }

  .simple-footer .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .simple-footer .footer-section ul li a:hover {
    color: #fff;
  }

  /* Footer Bottom */
  .simple-footer .footer-bottom {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #444;
    margin-top: 20px;
    width: 100%;
  }

  .simple-footer .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }

  .raffleSection {
    margin-top: 50px;
    margin-bottom: 80px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .raffleBox {
    background-color: rgb(228, 226, 226);
    border-radius: 20px;
    width: 35%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .mainRI {
    width: 100%;
    margin: auto;
    justify-content: center;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
  }

  .raffleCon {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .amount {
    font-size: 17px;
    font-weight: bolder;
  }

  .amountCon {
    font-size: 14px;
  }

  .tickets {
    display: flex;
    justify-content: center;
  }

  .tickets img {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-right: 10px;
  }

  .tickets div {
    color: #ff3700;
    font-weight: bold;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .endDate {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 5px;
  }

  .endDate img {
    width: 25px;
    height: 25px;
    margin-top: -3px;
    margin-left: 10px;
  }

  .location {
    margin-top: 20px;
  }

  .popup-content2 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.062);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .picAndDetails {
    display: flex;
    justify-content: space-evenly;
  }

  .detailslPic {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .s1H {
    font-size: 22px;
    color: black;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .s1Sub {
    font-size: 14px;
  }

  .mainR {
    width: 100%;
    margin-left: 5%;
  }

  .amountR {
    font-weight: bolder;
    font-size: 17px;
  }

  .description {
    font-size: 18px;
    margin-top: 20px;
  }

  .lineDash {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.192);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .s2H {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .s2H img {
    width: 20px;
    height: 20px;
    margin-top: -4px;
    margin-right: 5px;
  }

  .flexDetails {
    display: flex;
    justify-content: space-between;
  }

  .leftS {
    font-size: 16px;
    font-weight: bolder;
  }

  .rightS {
    font-size: 16px;
  }

  .section2 {
    margin-bottom: 20px;
  }

  .bar {
    height: 12px;
    width: 100%;
    background-color: #01840c2d;
    border-radius: 100px;
  }

  .note {
    font-size: 14px;
    color: #00000077;
  }

  .user img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .user {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    z-index: 10;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    padding: 5px;
    padding-bottom: 10px;
    width: 80%;
  }

  .timer div {
    text-align: center;
    width: 73px;
  }

  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  }

  .userMain {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .ticketDetailsMain {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    width: 100%;
  }

  .ticketDetails img {
    width: 100px;
    height: 100px;
  }

  .ticketDetails div {
    font-size: 14px;
    color: #808080;
    text-align: center;
  }

  .userTicket {
    background-color: #43913d33;
    width: 100%;
    padding: 20px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .userDetails {
    width: 100%;
    padding: 20px;
    background-color: #413f3f13;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

  }

  .note2Main {
    margin-top: 3%;

  }

  .note2 {
    display: flex;
    justify-content: space-between;
  }

  .noteC {
    width: 80%;
  }

  .report {
    margin: auto;
  }

  .report button {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 81, 81, 0);
    border: 1px solid rgb(248, 101, 101);
    border-radius: 100px;
    font-size: 18px;
    color: rgb(248, 101, 101);
    padding: 10px;
    width: 250px;
    transition: 0.3s ease-in-out;
  }

  .report button:hover {
    background-color: rgb(248, 101, 101);
    color: white;
  }

  .back {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    font-size: 25px;
    color: #4ba345;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .back img {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-top: -5px;
    transition: 0.3s ease-in-out;
  }



  .aboutSection2Mobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .login {
    font-size: 18px;
    border-radius: 100px;
    background-color: #4ba345;
    color: white;
    border: none;
    width: 100px;
    padding: 10px;
    margin-left: 8px;
    transition: 0.5s ease-in-out;

  }

  .login:hover {
    background-color: #5cc954;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .miniBarMain {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;
    color: #000000;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .allWrap {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0.116);
  }

  .cont {
    background: url('./assets/bg.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introductionMain {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    width: 90%;
  }

  .introduction {
    margin: auto;
  }

  .banner {
    width: 55%;
    height: 55%;
    margin-bottom: -140px;
    z-index: 100;
  }

  .line {
    width: 100%;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    z-index: 1;
    display: flex;
    font-size: 38px;
    font-weight: bold;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    background-color: #ffb84d;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    padding: 15px;
    width: fit-content;
  }

  .subTitle {
    font-size: 18px;
    color: #413f3f;
    width: 90%;
  }

  .button-7 {
    margin-top: 30px;
    border: 1px solid orange;
    border-radius: 50px;
    font-size: 17px;
    width: 150px;
    padding: 10px;
    background-color: white;
  }

  .bold {
    font-weight: bold;
  }

  .about {
    background-color: #ffffff;
    padding-bottom: 30px;
    padding-top: 60px;
  }

  .steps {
    background-color: #e5852e;
    padding-bottom: 60px;
    padding-top: 60px;
    display: flex;
    justify-content: space-evenly;
  }

  .introC {
    background-color: #01840d;
    padding-bottom: 60px;
    background: url('./assets/6230704.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .line2 {
    width: 100%;
  }

  .titleA {
    font-size: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    background-color: #ffb84d;
    padding: 10px;
    margin-bottom: 2%;
  }

  .titleS {
    font-size: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    margin-bottom: 2%;
  }

  .img1B {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .aboutSection1 div {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    width: 30%;
  }

  .aboutSection2 {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }


  .aboutSection2Sub {
    width: 400px;
  }

  .aboutSection2Sub div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutSection2Sub-2 img {
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub img {
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub-2 {
    width: 400px;
    margin-top: 70px;
  }

  .aboutSection2Sub-2 div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .titleS2 {
    font-size: 18px;
    color: white;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .contents {
    display: flex;
    color: white;
  }

  .stepsBtn {
    margin-top: 20px;
    padding: 12px;
    border-radius: 100px;
    border: none;
    width: 180px;
    font-weight: bold;
    transition: transform .6s;

  }

  .stepsBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contents1 {
    margin-right: 30px;
  }

  .steps img {
    width: 35%;
  }

  .contentsMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobileSection {
    display: flex;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .mobCon1 {
    font-size: 25px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 35%;
  }

  .mobCon2 {
    font-size: 25px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    text-align: right;
    width: 35%;
  }

  .mobileSection img {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .imgAndConMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .imgAndCon img {
    width: 60%;
    justify-content: center;
    margin: auto;
    display: flex;
  }

  .imgAndCon div {
    font-size: 25px;
    color: #f0d975;
    font-weight: bolder;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .imgAndCon p {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 90%;
    margin: auto;
  }

  /* General Footer Styles */
  .simple-footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    font-family: Arial, sans-serif;

    padding-bottom: 50px;
  }

  .simple-footer .container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .simple-footer .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .simple-footer .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 200px;
  }

  .simple-footer .footer-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .simple-footer .footer-section p,
  .simple-footer .footer-section ul,
  .simple-footer .footer-section a {
    font-size: 14px;
    color: #ccc;
    text-decoration: none;
    margin: 5px 0;
  }

  .simple-footer .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .simple-footer .footer-section ul li a:hover {
    color: #fff;
  }

  /* Footer Bottom */
  .simple-footer .footer-bottom {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #444;
    margin-top: 20px;
    width: 100%;
  }

  .simple-footer .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }

  .raffleSection {
    margin-top: 2.5%;
    margin-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .raffleBox {
    background-color: rgb(228, 226, 226);
    border-radius: 20px;
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .mainRI {
    width: 100%;
    margin: auto;
    justify-content: center;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
  }

  .raffleCon {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .amount {
    font-size: 17px;
    font-weight: bolder;
  }

  .amountCon {
    font-size: 14px;
  }

  .tickets {
    display: flex;
    justify-content: center;
  }

  .tickets img {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-right: 10px;
  }

  .tickets div {
    color: #ff3700;
    font-weight: bold;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .endDate {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 5px;
  }

  .endDate img {
    width: 25px;
    height: 25px;
    margin-top: -3px;
    margin-left: 10px;
  }

  .location {
    margin-top: 20px;
  }

  .popup-content2 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.062);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .picAndDetails {
    display: flex;
    justify-content: space-evenly;
  }

  .detailslPic {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .s1H {
    font-size: 22px;
    color: black;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .s1Sub {
    font-size: 14px;
  }

  .mainR {
    width: 100%;
    margin-left: 5%;
  }

  .amountR {
    font-weight: bolder;
    font-size: 17px;
  }

  .description {
    font-size: 18px;
    margin-top: 20px;
  }

  .lineDash {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.192);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .s2H {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .s2H img {
    width: 20px;
    height: 20px;
    margin-top: -4px;
    margin-right: 5px;
  }

  .flexDetails {
    display: flex;
    justify-content: space-between;
  }

  .leftS {
    font-size: 16px;
    font-weight: bolder;
  }

  .rightS {
    font-size: 16px;
  }

  .section2 {
    margin-bottom: 20px;
  }

  .bar {
    height: 12px;
    width: 100%;
    background-color: #01840c2d;
    border-radius: 100px;
  }

  .note {
    font-size: 14px;
    color: #00000077;
  }

  .user img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .user {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    z-index: 10;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    padding: 5px;
    padding-bottom: 10px;
    width: 80%;
  }

  .timer div {
    text-align: center;
    width: 73px;
  }

  .timer div span {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  }

  .userMain {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .ticketDetailsMain {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    width: 100%;
  }

  .ticketDetails img {
    width: 100px;
    height: 100px;
  }

  .ticketDetails div {
    font-size: 14px;
    color: #808080;
    text-align: center;
  }

  .userTicket {
    background-color: #43913d33;
    width: 100%;
    padding: 20px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .userDetails {
    width: 100%;
    padding: 20px;
    background-color: #413f3f13;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

  }

  .note2Main {
    margin-top: 3%;

  }

  .note2 {
    display: flex;
    justify-content: space-between;
  }

  .noteC {
    width: 80%;
  }

  .report {
    margin: auto;
  }

  .report button {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 81, 81, 0);
    border: 1px solid rgb(248, 101, 101);
    border-radius: 100px;
    font-size: 18px;
    color: rgb(248, 101, 101);
    padding: 10px;
    width: 250px;
    transition: 0.3s ease-in-out;
  }

  .report button:hover {
    background-color: rgb(248, 101, 101);
    color: white;
  }

  .back {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    font-size: 25px;
    color: #4ba345;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .back img {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-top: -5px;
    transition: 0.3s ease-in-out;
  }


  .aboutSection2Mobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .login {
    font-size: 18px;
    border-radius: 100px;
    background-color: #42913d;
    color: white;
    border: none;
    width: 100px;
    padding: 10px;
    margin-left: 8px;
    transition: 0.5s ease-in-out;

  }

  .login:hover {
    background-color: #5cc954;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .miniBarMain {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;
    color: #000000;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .allWrap {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0.116);
  }

  .cont {
    background: url('./assets/bg.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introductionMain {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    width: 85%;
  }

  .introduction {
    margin: auto;
  }

  .banner {
    width: 58%;
    margin-bottom: -60px;
    z-index: 100;
  }

  .line {
    width: 100%;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    z-index: 1;
    display: flex;
    font-size: 48px;
    font-weight: bold;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    background-color: #ffb84d;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    padding: 15px;
    width: fit-content;
  }

  .subTitle {
    font-size: 20px;
    color: #413f3f;
    width: 90%;
  }

  .button-7 {
    margin-top: 30px;
    border: 1px solid orange;
    border-radius: 50px;
    font-size: 18px;
    width: 150px;
    padding: 10px;
    background-color: white;
  }

  .bold {
    font-weight: bold;
  }

  .about {
    background-color: #ffffff;
    padding-bottom: 4%;
    padding-top: 4%;
  }

  .steps {
    background-color: #e5852e;
    padding-bottom: 4%;
    padding-top: 4%;
    display: flex;
    justify-content: space-evenly;
  }

  .introC {
    background-color: #01840d;
    padding-bottom: 4%;
    background: url('./assets/6230704.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .line2 {
    width: 100%;
  }

  .titleA {
    font-size: 35px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    background-color: #ffb84d;
    padding: 10px;
    margin-bottom: 2%;
  }

  .titleS {
    font-size: 35px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    margin-bottom: 2%;
  }

  .img1B {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .aboutSection1 div {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    width: 30%;
  }

  .aboutSection2 {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }


  .aboutSection2Sub {
    width: 400px;
  }

  .aboutSection2Sub div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .aboutSection2Sub-2 img {
    width: 110px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub img {
    width: 110px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    background-color: #ffb84d;
    padding: 18px;
    border-radius: 10px;
    border: 5px dashed #bd86337c;
  }

  .aboutSection2Sub-2 {
    width: 400px;
    margin-top: 70px;
  }

  .aboutSection2Sub-2 div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .titleS2 {
    font-size: 18px;
    color: white;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .contents {
    display: flex;
    color: white;
  }

  .stepsBtn {
    margin-top: 6%;
    padding: 12px;
    border-radius: 100px;
    border: none;
    width: 180px;
    font-weight: bold;
    transition: transform .6s;
  }

  .stepsBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contents1 {
    margin-right: 30px;
  }

  .steps img {
    width: 35%;
  }

  .contentsMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobileSection {
    display: flex;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .mobCon1 {
    font-size: 28px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 35%;
  }

  .mobCon2 {
    font-size: 28px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    text-align: right;
    width: 35%;
  }

  .mobileSection img {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .imgAndConMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .imgAndCon img {
    width: 60%;
    justify-content: center;
    margin: auto;
    display: flex;
  }

  .imgAndCon div {
    font-size: 30px;
    color: #f0d975;
    font-weight: bolder;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .imgAndCon p {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 70%;
    margin: auto;
  }

  /* General Footer Styles */
  .simple-footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    font-family: Arial, sans-serif;

    padding-bottom: 50px;
  }

  .simple-footer .container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .simple-footer .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .simple-footer .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 200px;
  }

  .simple-footer .footer-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .simple-footer .footer-section p,
  .simple-footer .footer-section ul,
  .simple-footer .footer-section a {
    font-size: 14px;
    color: #ccc;
    text-decoration: none;
    margin: 5px 0;
  }

  .simple-footer .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .simple-footer .footer-section ul li a:hover {
    color: #fff;
  }

  /* Footer Bottom */
  .simple-footer .footer-bottom {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #444;
    margin-top: 20px;
    width: 100%;
  }

  .simple-footer .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }

  .raffleSection {
    margin-top: 2.5%;
    margin-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .raffleBox {
    background-color: rgb(228, 226, 226);
    border-radius: 20px;
    width: 24%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .mainRI {
    width: 100%;
    margin: auto;
    justify-content: center;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
  }

  .raffleCon {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .amount {
    font-size: 17px;
    font-weight: bolder;
  }

  .amountCon {
    font-size: 14px;
  }

  .tickets {
    display: flex;
    justify-content: center;
  }

  .tickets img {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-right: 10px;
  }

  .tickets div {
    color: #ff3700;
    font-weight: bold;
  }
}

@media screen and (min-width: 1920px) {

  .popup-content2 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 40px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.062);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .picAndDetails {
    display: flex;
    justify-content: space-evenly;
  }

  .detailslPic {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .s1H {
    font-size: 34px;
    color: black;
    font-weight: bolder;
    margin-bottom: 30px;
  }

  .s1Sub {
    font-size: 20px;
  }

  .mainR {
    width: 100%;
    margin-left: 5%;
  }

  .amountR {
    font-weight: bolder;
    font-size: 24px;
  }

  .description {
    font-size: 24px;
    margin-top: 30px;
  }

  .lineDash {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.192);
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .s2H {
    font-size: 29px;
    font-weight: bolder;
    margin-bottom: 25px;
  }

  .s2H img {
    width: 32px;
    height: 32px;
    margin-top: -6px;
    margin-right: 9px;
  }

  .flexDetails {
    display: flex;
    justify-content: space-between;
  }

  .leftS {
    font-size: 22px;
    font-weight: bolder;
  }

  .rightS {
    font-size: 22px;
  }

  .section2 {
    margin-bottom: 30px;
  }

  .bar {
    height: 17px;
    width: 100%;
    background-color: #01840c2d;
    border-radius: 100px;
  }

  .note {
    font-size: 20px;
    color: #00000077;
  }

  .user img {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-top: -4px;
  }

  .user {
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 30px;
  }


  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.2rem;
    z-index: 10;
    border: 1px solid black;
    border-radius: 15px;
    padding: 15px;
    padding: 15px;
    padding-bottom: 10px;
    width: 80%;
  }

  .timer div {
    text-align: center;
    width: 73px;
  }

  .timer div span {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  }

  .userMain {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .ticketDetailsMain {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    width: 100%;
  }

  .ticketDetails img {
    width: 120px;
    height: 120px;
  }

  .ticketDetails div {
    font-size: 20px;
    color: #808080;
    text-align: center;
  }

  .userTicket {
    background-color: #43913d33;
    width: 100%;
    padding: 30px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }

  .userDetails {
    width: 100%;
    padding: 30px;
    background-color: #413f3f13;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;

  }

  .note2Main {
    margin-top: 3%;

  }

  .note2 {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
  }

  .noteC {
    width: 80%;
  }

  .report {
    margin: auto;
  }


  .endDate {
    font-size: 22px;
    font-weight: bolder;
    margin-bottom: 10px;
  }

  .endDate img {
    width: 35px;
    height: 35px;
    margin-top: -3px;
    margin-left: 15px;
  }

  .location {
    margin-top: 30px;
  }

  .report button {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 81, 81, 0);
    border: 1px solid rgb(248, 101, 101);
    border-radius: 100px;
    color: rgb(248, 101, 101);
    padding: 18px;
    width: 350px;
    transition: 0.3s ease-in-out;
    font-size: 22px;
  }

  .report button:hover {
    background-color: rgb(248, 101, 101);
    color: white;
  }

  .back {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    font-size: 35px;
    color: #4ba345;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .back img {
    width: 55px;
    height: 55px;
    margin-right: 35px;
    margin-top: -5px;
    transition: 0.3s ease-in-out;
  }


  .aboutSection2Mobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .login {
    font-size: 25px;
    border-radius: 100px;
    background-color: #4ba345;
    color: white;
    border: none;
    width: 150px;
    padding: 15px;
    margin-left: 10px;
    transition: 0.5s ease-in-out;

  }

  .login:hover {
    background-color: #5cc954;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .miniBarMain {
    display: none;
  }

  #fontSize {
    font-size: 25px;
    cursor: pointer;
    color: #000000;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .allWrap {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0.116);
  }

  .cont {
    background: url('./assets/bg.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introductionMain {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    width: 85%;
  }

  .introduction {
    margin: auto;
  }

  .banner {
    width: 58%;
    margin-bottom: -60px;
    z-index: 100;
  }

  .line {
    width: 100%;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    z-index: 1;
    display: flex;
    font-size: 68px;
    font-weight: bold;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    background-color: #ffb84d;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    padding: 20px;
    width: fit-content;
  }

  .subTitle {
    font-size: 28px;
    color: #413f3f;
    width: 90%;
  }

  .button-7 {
    margin-top: 30px;
    border: 1px solid orange;
    border-radius: 50px;
    font-size: 18px;
    width: 210px;
    padding: 16px;
    background-color: white;
    font-size: 24px;
  }

  .bold {
    font-weight: bold;
  }

  .about {
    background-color: #ffffff;
    padding-bottom: 4%;
    padding-top: 4%;
  }

  .steps {
    background-color: #e5852e;
    padding-bottom: 4%;
    padding-top: 4%;
    display: flex;
    justify-content: space-evenly;
  }

  .introC {
    background-color: #01840d;
    padding-bottom: 4%;
    background: url('./assets/6230704.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .line2 {
    width: 100%;
  }

  .titleA {
    font-size: 50px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    background-color: #ffb84d;
    padding: 10px;
    margin-bottom: 2%;
  }

  .titleS {
    font-size: 50px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    margin-bottom: 2%;
  }

  .img1B {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .aboutSection1 div {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    width: 30%;
    font-size: 22px;
  }

  .aboutSection2 {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }


  .aboutSection2Sub {
    width: 400px;
  }

  .aboutSection2Sub div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-size: 22px;
  }

  .aboutSection2Sub-2 img {
    width: 160px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 25px;
    background-color: #ffb84d;
    padding: 27px;
    border-radius: 15px;
    border: 7px dashed #bd86337c;
  }

  .aboutSection2Sub img {
    width: 160px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 25px;
    background-color: #ffb84d;
    padding: 27px;
    border-radius: 15px;
    border: 7px dashed #bd86337c;
  }

  .aboutSection2Sub-2 {
    width: 400px;
    margin-top: 70px;
  }

  .aboutSection2Sub-2 div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-size: 22px;
  }

  .titleS2 {
    font-size: 25px;
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .contents {
    display: flex;
    color: white;
    font-size: 22px;
  }

  .contents p {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .stepsBtn {
    margin-top: 6%;
    padding: 20px;
    border-radius: 100px;
    border: none;
    width: 270px;
    font-weight: bold;
    font-size: 22px;
    transition: transform .6s;

  }

  .stepsBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contents1 {
    margin-right: 45px;
  }

  .steps img {
    width: 35%;
  }

  .contentsMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobileSection {
    display: flex;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .mobCon1 {
    font-size: 40px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 35%;
  }

  .mobCon2 {
    font-size: 40px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    text-align: right;
    width: 35%;
  }

  .mobileSection img {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .imgAndConMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .imgAndCon img {
    width: 60%;
    justify-content: center;
    margin: auto;
    display: flex;
  }

  .imgAndCon div {
    font-size: 42px;
    color: #f0d975;
    font-weight: bolder;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .imgAndCon p {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 70%;
    margin: auto;
    font-size: 22px;
  }

  /* General Footer Styles */
  .simple-footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
    font-family: Arial, sans-serif;
    padding-bottom: 50px;
  }

  .simple-footer .container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .simple-footer .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .simple-footer .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 200px;
  }

  .simple-footer .footer-section h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .simple-footer .footer-section p,
  .simple-footer .footer-section ul,
  .simple-footer .footer-section a {
    font-size: 20px;
    color: #ccc;
    text-decoration: none;
    margin: 10px 0;
  }

  .simple-footer .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .simple-footer .footer-section ul li a:hover {
    color: #fff;
  }

  /* Footer Bottom */
  .simple-footer .footer-bottom {
    text-align: center;
    padding: 18px 0;
    border-top: 1px solid #444;
    margin-top: 20px;
    width: 100%;
  }

  .simple-footer .footer-bottom p {
    margin: 0;
    font-size: 20px;
  }

  .raffleSection {
    margin-top: 2.5%;
    margin-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .raffleBox {
    background-color: rgb(228, 226, 226);
    border-radius: 30px;
    width: 24%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .mainRI {
    width: 100%;
    margin: auto;
    justify-content: center;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
  }

  .raffleCon {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .amount {
    font-size: 25px;
    font-weight: bolder;
  }

  .amountCon {
    font-size: 21px;
  }

  .tickets {
    display: flex;
    justify-content: center;
  }

  .tickets img {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    margin-right: 10px;
  }

  .tickets div {
    color: #ff3700;
    font-weight: bold;
    font-size: 22px;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .popup-content2 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 60px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.062);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .picAndDetails {
    display: flex;
    justify-content: space-evenly;
  }

  .detailslPic {
    width: 42%;
    height: 42%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .s1H {
    font-size: 45px;
    color: black;
    font-weight: bolder;
    margin-bottom: 45px;
  }

  .s1Sub {
    font-size: 25px;
  }

  .mainR {
    width: 100%;
    margin-left: 5%;
  }

  .amountR {
    font-weight: bolder;
    font-size: 32px;
  }

  .description {
    font-size: 34px;
    margin-top: 45px;
  }

  .lineDash {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.192);
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .s2H {
    font-size: 40px;
    font-weight: bolder;
    margin-bottom: 30px;
  }

  .s2H img {
    width: 42px;
    height: 42px;
    margin-top: -6px;
    margin-right: 9px;
  }

  .flexDetails {
    display: flex;
    justify-content: space-between;
  }

  .leftS {
    font-size: 30px;
    font-weight: bolder;
  }

  .rightS {
    font-size: 30px;
  }

  .section2 {
    margin-bottom: 45px;
  }

  .bar {
    height: 25px;
    width: 100%;
    background-color: #01840c2d;
    border-radius: 100px;
  }

  .note {
    font-size: 27px;
    color: #00000077;
  }

  .user img {
    width: 55px;
    height: 55px;
    margin-right: 25px;
    margin-top: -4px;
  }

  .user {
    font-size: 27px;
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .city {
    font-size: 25px;
  }

  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.9rem;
    z-index: 10;
    border: 1px solid black;
    border-radius: 25px;
    padding: 40px;
    padding: 20px;
    padding-right: 40px;
    padding-bottom: 20px;
    width: 80%;
  }

  .timer div {
    text-align: center;
    width: 73px;
  }

  .timer div span {
    display: block;
    font-size: 1.5rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
  }

  .userMain {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .ticketDetailsMain {
    display: flex;
    justify-content: space-evenly;
    margin-top: 45px;
    width: 100%;
  }

  .ticketDetails img {
    width: 200px;
    height: 200px;
  }

  .ticketDetails div {
    font-size: 32px;
    color: #808080;
    text-align: center;
  }

  .userTicket {
    background-color: #43913d33;
    width: 100%;
    padding: 50px;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
  }

  .userDetails {
    width: 100%;
    padding: 50px;
    background-color: #413f3f13;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;

  }

  .note2Main {
    margin-top: 3%;

  }

  .note2 {
    display: flex;
    justify-content: space-between;
    font-size: 32px;
  }

  .noteC {
    width: 80%;
  }

  .report {
    margin: auto;
  }


  .endDate {
    font-size: 32px;
    font-weight: bolder;
    margin-bottom: 10px;
  }

  .endDate img {
    width: 50px;
    height: 50px;
    margin-top: -3px;
    margin-left: 15px;
  }

  .location {
    margin-top: 45px;
  }

  .report button {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 81, 81, 0);
    border: 1px solid rgb(248, 101, 101);
    border-radius: 100px;
    color: rgb(248, 101, 101);
    padding: 22px;
    width: 450px;
    transition: 0.3s ease-in-out;
    font-size: 32px;
  }

  .report button:hover {
    background-color: rgb(248, 101, 101);
    color: white;
  }

  .back {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    font-size: 45px;
    color: #4ba345;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .back img {
    width: 75px;
    height: 75px;
    margin-right: 55px;
    margin-top: -5px;
    transition: 0.3s ease-in-out;
  }


  .aboutSection2Mobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .login {
    font-size: 33px;
    border-radius: 100px;
    background-color: #4ba345;
    color: white;
    border: none;
    width: 200px;
    padding: 20px;
    margin-left: 13px;
    transition: 0.5s ease-in-out;

  }

  .login:hover {
    background-color: #5cc954;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .miniBarMain {
    display: none;
  }

  #fontSize {
    font-size: 33px;
    cursor: pointer;
    color: #000000;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 33px;
    margin-right: 33px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 48px;
    height: 48px;
    margin-left: 13px;
    margin-right: 13px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .allWrap {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0.116);
  }

  .cont {
    background: url('./assets/bg.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introductionMain {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    width: 85%;
  }

  .introduction {
    margin: auto;
  }

  .banner {
    width: 58%;
    margin-bottom: -60px;
    z-index: 100;
  }

  .line {
    width: 100%;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    z-index: 1;
    display: flex;
    font-size: 90px;
    font-weight: bold;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    background-color: #ffb84d;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    padding: 20px;
    width: fit-content;
  }

  .subTitle {
    font-size: 38px;
    color: #413f3f;
    width: 90%;
  }

  .button-7 {
    margin-top: 50px;
    border: 1px solid orange;
    border-radius: 50px;
    width: 300px;
    padding: 20px;
    background-color: white;
    font-size: 34px;
  }

  .bold {
    font-weight: bold;
  }

  .about {
    background-color: #ffffff;
    padding-bottom: 4%;
    padding-top: 4%;
  }

  .steps {
    background-color: #e5852e;
    padding-bottom: 4%;
    padding-top: 4%;
    display: flex;
    justify-content: space-evenly;
  }

  .introC {
    background-color: #01840d;
    padding-bottom: 4%;
    background: url('./assets/6230704.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .line2 {
    width: 100%;
  }

  .titleA {
    font-size: 67px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    background-color: #ffb84d;
    padding: 10px;
    margin-bottom: 2%;
  }

  .titleS {
    font-size: 67px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    margin-bottom: 2%;
  }

  .img1B {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .aboutSection1 div {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    width: 30%;
    font-size: 30px;
  }

  .aboutSection2 {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }


  .aboutSection2Sub {
    width: 650px;
  }

  .aboutSection2Sub div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-size: 30px;
  }

  .aboutSection2Sub-2 img {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 35px;
    background-color: #ffb84d;
    padding: 35px;
    border-radius: 25px;
    border: 10px dashed #bd86337c;
  }

  .aboutSection2Sub img {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 35px;
    background-color: #ffb84d;
    padding: 35px;
    border-radius: 25px;
    border: 10px dashed #bd86337c;
  }

  .aboutSection2Sub-2 {
    width: 650px;
    margin-top: 130px;
  }

  .aboutSection2Sub-2 div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-size: 30px;
  }

  .titleS2 {
    font-size: 33px;
    color: white;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .contents {
    display: flex;
    color: white;
    font-size: 30px;
  }

  .contents p {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .stepsBtn {
    margin-top: 6%;
    padding: 22px;
    border-radius: 100px;
    border: none;
    width: 340px;
    font-weight: bold;
    font-size: 32px;
    transition: transform .6s;

  }

  .stepsBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contents1 {
    margin-right: 45px;
  }

  .steps img {
    width: 35%;
  }

  .contentsMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobileSection {
    display: flex;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .mobCon1 {
    font-size: 52px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 35%;
  }

  .mobCon2 {
    font-size: 52px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    text-align: right;
    width: 35%;
  }

  .mobileSection img {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .imgAndConMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .imgAndCon img {
    width: 60%;
    justify-content: center;
    margin: auto;
    display: flex;
  }

  .imgAndCon div {
    font-size: 55px;
    color: #f0d975;
    font-weight: bolder;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .imgAndCon p {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 70%;
    margin: auto;
    font-size: 30px;
  }

  /* General Footer Styles */
  .simple-footer {
    background-color: #333;
    color: #fff;
    font-family: Arial, sans-serif;
    width: 100%;
    padding-bottom: 60px;

  }

  .simple-footer .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .simple-footer .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .simple-footer .footer-section {
    flex: 1;
    margin: 20px;
    min-width: 200px;
  }

  .simple-footer .footer-section h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .simple-footer .footer-section p,
  .simple-footer .footer-section ul,
  .simple-footer .footer-section a {
    font-size: 24px;
    color: #ccc;
    text-decoration: none;
    margin: 10px 0;
  }

  .simple-footer .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .simple-footer .footer-section ul li a:hover {
    color: #fff;
  }

  /* Footer Bottom */
  .simple-footer .footer-bottom {
    text-align: center;
    padding: 18px 0;
    border-top: 1px solid #444;
    margin-top: 20px;
    width: 100%;
  }

  .simple-footer .footer-bottom p {
    margin: 0;
    font-size: 26px;
  }

  .raffleSection {
    margin-top: 2.5%;
    margin-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .raffleBox {
    background-color: rgb(228, 226, 226);
    border-radius: 40px;
    width: 24%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 9px, rgba(0, 0, 0, 0.23) 0px 6px 9px;
  }

  .mainRI {
    width: 100%;
    margin: auto;
    justify-content: center;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
  }

  .raffleCon {
    display: flex;
    justify-content: space-between;
    padding: 30px;
  }

  .amount {
    font-size: 32px;
    font-weight: bolder;
  }

  .amountCon {
    font-size: 28px;
  }

  .tickets {
    display: flex;
    justify-content: center;
  }

  .tickets img {
    width: 40px;
    height: 40px;
    margin-top: 2px;
    margin-right: 15px;
  }

  .tickets div {
    color: #ff3700;
    font-weight: bold;
    font-size: 29px;
  }
}

@media screen and (min-width: 3840px) {

  .popup-content2 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 80px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.062);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .picAndDetails {
    display: flex;
    justify-content: space-evenly;
  }

  .detailslPic {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .s1H {
    font-size: 60px;
    color: black;
    font-weight: bolder;
    margin-bottom: 45px;
  }

  .s1Sub {
    font-size: 42px;
  }

  .mainR {
    width: 100%;
    margin-left: 5%;
  }

  .amountR {
    font-weight: bolder;
    font-size: 48px;
  }

  .description {
    font-size: 48px;
    margin-top: 45px;
  }

  .lineDash {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.192);
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .s2H {
    font-size: 60px;
    font-weight: bolder;
    margin-bottom: 45px;
  }

  .s2H img {
    width: 60px;
    height: 60px;
    margin-top: -9px;
    margin-right: 9px;
  }

  .flexDetails {
    display: flex;
    justify-content: space-between;
  }

  .leftS {
    font-size: 45px;
    font-weight: bolder;
  }

  .rightS {
    font-size: 45px;
  }

  .section2 {
    margin-bottom: 45px;
  }

  .bar {
    height: 35px;
    width: 100%;
    background-color: #01840c2d;
    border-radius: 100px;
  }

  .note {
    font-size: 38px;
    color: #00000077;
  }

  .user img {
    width: 80px;
    height: 80px;
    margin-right: 55px;
    margin-top: -4px;
  }

  .user {
    font-size: 45px;
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .city {
    font-size: 45px;
  }

  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 4rem;
    z-index: 10;
    border: 1px solid black;
    border-radius: 30px;
    padding: 40px;
    padding: 40px;
    padding-right: 60px;
    padding-bottom: 40px;
    width: 80%;
  }

  .timer div {
    text-align: center;
    width: 100px;
  }

  .timer div span {
    display: block;
    font-size: 2rem;
    font-weight: normal;
    color: #555;
    font-family: 'Edo', sans-serif;
    text-align: center;
  }

  .userMain {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .ticketDetailsMain {
    display: flex;
    justify-content: space-evenly;
    margin-top: 55px;
    width: 100%;
  }

  .ticketDetails img {
    width: 300px;
    height: 300px;
  }

  .ticketDetails div {
    font-size: 45px;
    color: #808080;
    text-align: center;
  }

  .userTicket {
    background-color: #43913d33;
    width: 100%;
    padding: 80px;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
  }

  .userDetails {
    width: 100%;
    padding: 80px;
    background-color: #413f3f13;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;

  }

  .note2Main {
    margin-top: 3%;

  }

  .note2 {
    display: flex;
    justify-content: space-between;
    font-size: 45px;
  }

  .noteC {
    width: 80%;
  }

  .report {
    margin: auto;
  }


  .endDate {
    font-size: 45px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .endDate img {
    width: 70px;
    height: 70px;
    margin-top: -3px;
    margin-left: 15px;
  }

  .location {
    margin-top: 45px;
  }

  .report button {
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 81, 81, 0);
    border: 1px solid rgb(248, 101, 101);
    border-radius: 100px;
    color: rgb(248, 101, 101);
    padding: 35px;
    width: 700px;
    transition: 0.3s ease-in-out;
    font-size: 45px;
  }

  .report button:hover {
    background-color: rgb(248, 101, 101);
    color: white;
  }

  .back {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    font-size: 68px;
    color: #4ba345;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .back img {
    width: 100px;
    height: 100px;
    margin-right: 75px;
    margin-top: -5px;
    transition: 0.3s ease-in-out;
  }


  .aboutSection2Mobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .login {
    font-size: 53px;
    border-radius: 100px;
    background-color: #4ba345;
    color: white;
    border: none;
    width: 280px;
    padding: 25px;
    margin-left: 22px;
    transition: 0.5s ease-in-out;

  }

  .login:hover {
    background-color: #5cc954;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .miniBarMain {
    display: none;
  }

  #fontSize {
    font-size: 53px;
    cursor: pointer;
    color: #000000;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 58px;
    margin-right: 58px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 73px;
    height: 73px;
    margin-left: 22px;
    margin-right: 22px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .allWrap {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0.116);
  }

  .cont {
    background: url('./assets/bg.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introductionMain {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    width: 85%;
  }

  .introduction {
    margin: auto;
  }

  .banner {
    width: 65%;
    margin-bottom: -190px;
    z-index: 100;
  }

  .line {
    width: 100%;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    z-index: 1;
    display: flex;
    font-size: 140px;
    font-weight: bold;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    background-color: #ffb84d;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    padding: 30px;
    width: fit-content;
  }

  .subTitle {
    font-size: 58px;
    color: #413f3f;
    width: 90%;
  }

  .button-7 {
    margin-top: 100px;
    border: 1px solid orange;
    border-radius: 100px;
    width: 450px;
    padding: 40px;
    background-color: white;
    font-size: 50px;
  }

  .bold {
    font-weight: bold;
  }

  .about {
    background-color: #ffffff;
    padding-bottom: 4%;
    padding-top: 4%;
  }

  .steps {
    background-color: #e5852e;
    padding-bottom: 4%;
    padding-top: 4%;
    display: flex;
    justify-content: space-evenly;
  }

  .introC {
    background-color: #01840d;
    padding-bottom: 4%;
    background: url('./assets/6230704.png') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .line2 {
    width: 100%;
  }

  .titleA {
    font-size: 100px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    background-color: #ffb84d;
    padding: 30px;
    margin-bottom: 2%;
  }

  .titleS {
    font-size: 100px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lilita One", sans-serif;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.068), 0px -5px 35px rgba(255, 255, 255, 0.103);
    margin-bottom: 2%;
  }

  .img1B {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .aboutSection1 div {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    width: 30%;
    font-size: 45px;
  }

  .aboutSection2 {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }


  .aboutSection2Sub {
    width: 780px;
  }

  .aboutSection2Sub div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-size: 45px;
  }

  .aboutSection2Sub-2 img {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 45px;
    background-color: #ffb84d;
    padding: 50px;
    border-radius: 35px;
    border: 13px dashed #bd86337c;
  }

  .aboutSection2Sub img {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 45px;
    background-color: #ffb84d;
    padding: 50px;
    border-radius: 35px;
    border: 13px dashed #bd86337c;
  }

  .aboutSection2Sub-2 {
    width: 780px;
    margin-top: 160px;
  }

  .aboutSection2Sub-2 div {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-size: 45px;
  }

  .titleS2 {
    font-size: 50px;
    color: white;
    margin-top: 70px;
  }

  .contents {
    display: flex;
    color: white;
    font-size: 45px;
  }

  .contents p {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .stepsBtn {
    margin-top: 6%;
    padding: 35px;
    border-radius: 100px;
    border: none;
    width: 490px;
    font-weight: bold;
    font-size: 45px;
    transition: transform .6s;

  }

  .stepsBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contents1 {
    margin-right: 75px;
  }

  .steps img {
    width: 35%;
  }

  .contentsMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobileSection {
    display: flex;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .mobCon1 {
    font-size: 80px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    width: 35%;
  }

  .mobCon2 {
    font-size: 80px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    text-align: right;
    width: 35%;
  }

  .mobileSection img {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .imgAndConMain {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .imgAndCon img {
    width: 60%;
    justify-content: center;
    margin: auto;
    display: flex;
  }

  .imgAndCon div {
    font-size: 75px;
    color: #f0d975;
    font-weight: bolder;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .imgAndCon p {
    color: rgb(255, 255, 255);
    text-align: center;
    width: 70%;
    margin: auto;
    font-size: 45px;
  }

  /* General Footer Styles */
  .simple-footer {
    background-color: #333;
    color: #fff;
    font-family: Arial, sans-serif;
    width: 100%;
    padding-bottom: 60px;
  }

  .simple-footer .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .simple-footer .footer-content {
    display: flex;
    justify-content: space-between;
    width: 2000px;
    flex-wrap: wrap;
  }

  .simple-footer .footer-section {
    flex: 1;
    margin: 20px;
    min-width: 200px;
  }

  .simple-footer .footer-section h2 {
    font-size: 45px;
    margin-bottom: 30px;
  }

  .simple-footer .footer-section p,
  .simple-footer .footer-section ul,
  .simple-footer .footer-section a {
    font-size: 32px;
    color: #ccc;
    text-decoration: none;
    margin: 10px 0;
  }

  .simple-footer .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .simple-footer .footer-section ul li a:hover {
    color: #fff;
  }

  /* Footer Bottom */
  .simple-footer .footer-bottom {
    text-align: center;
    padding: 18px 0;
    border-top: 1px solid #444;
    margin-top: 20px;
    width: 100%;
    width: 2000px;

  }

  .simple-footer .footer-bottom p {
    margin: 0;
    font-size: 34px;
  }

  .raffleSection {
    margin-top: 2.5%;
    margin-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .raffleBox {
    background-color: rgb(228, 226, 226);
    border-radius: 60px;
    width: 24%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 15px, rgba(0, 0, 0, 0.23) 0px 10px 15px;
  }

  .mainRI {
    width: 100%;
    margin: auto;
    justify-content: center;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
  }

  .raffleCon {
    display: flex;
    justify-content: space-between;
    padding: 45px;
  }

  .amount {
    font-size: 48px;
    font-weight: bolder;
  }

  .amountCon {
    font-size: 43px;
  }

  .tickets {
    display: flex;
    justify-content: center;
  }

  .tickets img {
    width: 60px;
    height: 60px;
    margin-top: 2px;
    margin-right: 25px;
  }

  .tickets div {
    color: #ff3700;
    font-weight: bold;
    font-size: 42px;
  }
}